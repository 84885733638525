import PricingCard from "../PricingCard"
import Button from "../../button"
import classnames from "../../../helpers/classnames"
import * as React from "react"
import styles from "../pricing.module.scss"

const CustomCard = ({ price, oldPrice, href, moneyBackGuarantee, handleButtonClick, ...rest }) => {

  return (
    <PricingCard
      big
      moneyBackGuarantee={moneyBackGuarantee}
      title="Complete Masterclass"
      price={"Custom price for 5+ licenses"}
      footer={
        <Button
          id="main-custom-contact"
          width="200px"
          target="_blank"
          outline
          onClick={handleButtonClick}
          className={classnames("mx-auto mt-0")}

        >CONTACT US</Button>
      }
      {...rest}
    >
      <ul>
        <li>
          <strong>8h video lectures </strong>
          <br />
          (English, Portuguese <br/>
          & Italian subs)
        </li>
        <li>
          <strong>14 eBooks</strong> (in total 450+ pages)
        </li>
        <li>
          <strong>Ideation prompts</strong>
        </li>
        <li>
          <strong>Certificate</strong>
        </li>
      </ul>
    </PricingCard>
  )
}

export default CustomCard
