import styles from "./styles.module.scss"
import rorySutherlandDealBook from "../../../images/rory-sutherland-offer/book.png"
import * as React from "react"
import Tooltip from "../../tooltip/Tooltip"
import classnames from "../../../helpers/classnames"

const RorySutherlandBadge = ({ className, ...rest }) => {
  return (
    <div className={classnames(styles.rorySutherlandBadge, className)} {...rest}>
      <Tooltip
        content={"We'll email you after the purchase to get your shipping address and to let you know about the delivery date."}
        closable={false}
        followCursor
      >
        <div className={styles.rorySutherlandBadgeWrapper}>
          <span className={styles.rorySutherlandBadgeTitle}>Special offer</span>
          <div className={styles.rorySutherlandBadgeText}>
            Complete Masterclass <br/>
            + <b>signed Alchemy</b><br/>
            by Rory Sutherland <b>for free</b>
          </div>
          <img className={styles.rorySutherlandBadgeImage} src={rorySutherlandDealBook}
               alt="rory sutherland signed book"/>
        </div>
      </Tooltip>
    </div>
  )
}

export default RorySutherlandBadge
