import React from "react"
import styles from "./pricing.module.scss"

const PricingWrapper = ({children, ...rest}) => {
  return (
    <div
      id="pricing"
      className={styles.cardsList}
      {...rest}
    >
      {children}
    </div>
  )
}

export default PricingWrapper;
