import React from "react"
import { useMedia } from "../../../helpers/window"

const OnlyMedia = ({
                     children,
                     xs = false,
                     sm = false,
                     md = false,
                     lg = false,
                     mobile = false,
                     tablet = false,
                     desktop = false,
                     ...rest
                   }) => {
  const { name, size } = useMedia()
  let show = (name === "xs" && (mobile || xs))
    || (name === "sm" && (mobile || sm))
    || (name === "md" && (tablet || md))
    || (name === "lg" && (desktop || lg))
  return (
    <div>
      {show ? children : ``}
    </div>
  )
}

export default OnlyMedia
