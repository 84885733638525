import PricingCard from "../PricingCard"
import Button from "../../button"
import classnames from "../../../helpers/classnames"
import * as React from "react"
import styles from "../pricing.module.scss"

const VideoCard = ({ price, oldPrice, href, ...rest }) => {
  return (
    <PricingCard
      title="Video lectures"
      oldPrice={oldPrice}
      price={price}
      footer={
        <Button
          id="main-video-buy"
          href={href}
          width="200px"
          target="_blank"
          outline
          className={classnames("mx-auto mt-0")}
        >ENROLL NOW</Button>
      }
    >
      <ul>
        <li>
          <strong>8h video lectures</strong>
          <br/>
          (English & Portuguese subs)
        </li>
      </ul>
    </PricingCard>
  )
}

export default VideoCard
