import PricingCard from "../PricingCard"
import Button from "../../button"
import classnames from "../../../helpers/classnames"
import * as React from "react"
import styles from "../pricing.module.scss"
import OnlyMedia from "../../media/OnlyMedia/OnlyMedia"
import rorySutherlandDealBook from "../../../images/rory-sutherland-offer/book.png"
import RorySutherlandBadge from "../RorySutherlandBadge/RorySutherlandBadge"

const CompleteCard = ({
                        buttonId = "main-complete-buy",
                        bVersion = false,
                        price,
                        oldPrice,
                        href,
                        moneyBackGuarantee = false,
                        udemyPromotion = false,
                        rorySutherlandSigned = false,
                        ...rest
                      }) => {
  return (
    <PricingCard
      big
      outlined
      moneyBackGuarantee={moneyBackGuarantee}
      udemyPromotion={udemyPromotion}
      title="Complete Masterclass"
      oldPrice={oldPrice}
      price={price}
      footer={
        <Button
          id={buttonId}
          width="200px"
          target="_blank"
          href={href}
          className={classnames("mx-auto mt-0")}

        >ENROLL NOW</Button>
      }
      {...rest}
    >
      {rorySutherlandSigned &&
      <div className={styles.rorySutherlandBadge}>
        <OnlyMedia desktop>
          <RorySutherlandBadge />
        </OnlyMedia>
      </div>
      }
      <ul>
        <li>
          <strong>8h video lectures </strong>
          <br />
          (English, Portuguese <br/>
          & Italian subs)
        </li>
        <li>
          <strong>14 eBooks</strong>{bVersion ? <br /> : " "}(in total 450+ pages)
        </li>
        <li>
          <strong>Ideation prompts</strong>
        </li>
        <li>
          <strong>Certificate</strong>
        </li>

        {rorySutherlandSigned &&
        <OnlyMedia mobile tablet>
          <li>
            <b className={classnames("red")}>
              + signed Alchemy <br />
              by Rory Sutherland
            </b>
          </li>
        </OnlyMedia>
        }

      </ul>
    </PricingCard>
  )
}

export default CompleteCard
