import { useState, useEffect } from 'react';

function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  } else {
    return {width: 0, height: 0};
  }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useMedia() {
  if (typeof window !== 'undefined' && window.matchMedia) {
    const queries = {
      xs: '(max-width: 320px)',
      sm: '(min-width: 321px) and (max-width: 720px)',
      md: '(min-width: 721px) and (max-width: 1024px)',
      lg: '(min-width: 1025px)'
    }

    let keys = Object.keys(queries);
    let windowWidth = window.innerWidth;
    for (let i = 0; i < keys.length; i++) {
      if (window.matchMedia(queries[keys[i]]).matches) {
        return {
          name: keys[i],
          size: windowWidth
        };
      }
    }

    return {
      name: "lg",
      size: windowWidth
    };
  }

  return {
    name: null,
    size: null,
  }
}
