import React, { Component } from 'react'
import Button from '../../button'
import Popup from '../../popup'
import { Link } from 'gatsby';
import { validateEmail } from '../../../lib/validators'
import { sendEmail } from "../../../lib/sendEmail"
import modalStyles from "../../popup/popup.module.scss"

export default class ContactPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '', // form field
      message: '', // form field
      isPolicyAccepted: false, // form field

      feedbackMessage: ''
    }

    this.isFormValid = this.isFormValid.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  /**
   * Returns bool,
   * every field must be filled in form to be valid.
   */
  isFormValid() {
    const { email, message, isPolicyAccepted } = this.state
    return isPolicyAccepted && validateEmail(email) && message !== ''
  }

  handleFormSubmit(e) {
    e.preventDefault()

    const emailHTML = `
      <b>Email:</b> ${this.state.email}<br>
      <b>Message:</b> ${this.state.message}<br>
    `;
    const emailTXT = `
    - Email: ${this.state.email}\n
    - Message: ${this.state.message}\n
    `

    // Sends composed email
    sendEmail({
      to: "info@mindworxacademy.com",
      site: "mindworxacademy.com",
      subject: "Custom pricing",
      text: emailTXT,
      html: emailHTML
    })
      .then(_ => {
        this.setState({ feedbackMessage: 'Thank you! We’ll get back to you soon.' })
      })
      .catch(_ => {
        this.setState({ feedbackMessage: 'Error has occurred, please check all fields and try submitting again.' })
      })
  }

  render() {
    return (
      <Popup show={this.props.show} handleClose={this.props.handleClose}>
        <h2 className={modalStyles.title}>
          Thanks for your interest
        </h2>
        <p className={modalStyles.text}>
          How many licenses you are interested in?<br />
          We'll get back to you with an offer real quick.
        </p>

        <form
          className={modalStyles.form}
          // action="/newsletter/subscribe-plus"
          method="post"
          // noValidate
          onSubmit={this.handleFormSubmit}
        >
          <input
            className={modalStyles.inputText}
            name="email"
            placeholder="Your email"
            autoComplete="off"
            type="email"
            required
            onChange={e => this.setState({ email: e.target.value })}
          />
          <textarea
            placeholder="Your message"
            required
            onChange={e => this.setState({ message: e.target.value })}
            className={modalStyles.inputText}
            rows={2}></textarea>

          <div className={modalStyles.checkboxWrapper}>
            <input
              type="checkbox"
              className={modalStyles.checkboxInput}
              id="contact-popup-checkbox"
              onChange={_ => {
                this.setState(state => ({
                  isPolicyAccepted: !state.isPolicyAccepted,
                }))
              }
              }
            />

            <label
              htmlFor="contact-popup-checkbox"
              style={{
                fontSize: '12px',
                paddingLeft: '28px',
                fontWeight: '400',
              }}
            >
              {' '}
              I agree to the
              <Link className={modalStyles.link} to="/privacy-policy">
                privacy policy terms.
              </Link>
            </label>
          </div>

          <Button size="popup" type="submit" disabled={!this.isFormValid()}>Submit your answer</Button>

          <div className={modalStyles.message}>{this.state.feedbackMessage}</div>
        </form>
      </Popup>
    )
  }
}
