import React, { useState } from "react"
import { Tooltip as TippyTooltip } from "react-tippy"
import styles from "./styles.module.scss"
import "react-tippy/dist/tippy.css"

const Tooltip = ({
                   children,
                   content,
                   closable = true,
                   offset="0, 0",
                   ...rest
                 }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const open = e => {
    setIsOpen(true)
  }

  const close = e => {
    setIsHovered(false)
    setIsClicked(false)
    setIsOpen(false)
  }

  const onTriggerClick = () => {
    setIsClicked(true)
    open()
  }

  const onTooltipMouseOver = () => {
    setIsHovered(true)
    open()
  }

  const onTriggerMouseLeave = e => {
    if (!isClicked) {
      close()
    }
  }

  const onTooltipMouseLeave = () => {
    if (!isClicked) {
      close()
    }
  }

  return (
    <TippyTooltip
      open={isOpen}
      onRequestClose={close}
      {...rest}
      html={(
        <div
          style={{}}
          className="tooltip-body"
          onMouseOver={onTooltipMouseOver}
          onMouseLeave={onTooltipMouseLeave}
        >
          {closable &&
          <span className="close" onClick={close}>&#215;</span>
          }
          <div onClick={close} className="tooltip-content">
            {content}
          </div>
        </div>
      )}
    >
      <ins
        className={styles.content}
        onMouseOver={open}
        onMouseLeave={onTriggerMouseLeave}
        onClick={onTriggerClick}
      >
        {children}
      </ins>
    </TippyTooltip>
  )
}
Tooltip.defaultProps = {
  theme: "classic",
  animation: "fade",
  arrow: true,
  delay: 150,
  trigger: "click",
  allowHTML: true,
  interactive: true,
  hideOnClick: true
}

export default Tooltip
