import React from "react"
import styles from "./pricing.module.scss"
import Button from "../button"
import classnames from "../../helpers/classnames"

const PricingCard = ({
                       children,
                       footer,
                       title = "Complete Masterclass",
                       oldPrice = null,
                       price,
                       big = false,
                       buttonLabel = "ENROLL NOW",
                       moneyBackGuarantee = false,
                       promotion=null,
                       udemyPromotion = false,
                       outlined = false,
                       className,
                       ...rest
                     }) => {
  let hasOldPrice = oldPrice !== null && oldPrice !== ""

  return (
    <div
      {...rest}
      className={classnames(
        className,
        styles.card,
        big ? styles.cardBig : null,
        moneyBackGuarantee ? styles.cardMoneybackGuarantee : null,
        outlined ? styles.cardOutlined : null
      )}
    >
      {udemyPromotion &&
        <div className={classnames(
          styles.card__udemyPromotion
        )}>
          Save <strong>40%</strong>
          <br/>
          with the code
          <br/>
          <strong>MWAUDM150</strong>
        </div>
      }

      {promotion &&
      <div className={classnames(
        styles.card__promotion
      )}>
        {promotion}
      </div>
      }

      <h3
        className={styles.card__title}
      >{title}</h3>

      <div className={classnames(styles.card__content)}>
        {children}
      </div>

      <div className={styles.price}>
        <div className={styles.card__priceOld}>
          {hasOldPrice &&
            <h4 className={styles.card__oldPrice}>{oldPrice}</h4>
          }
        </div>
        <h4
          className={styles.card__price}
          style={{ color: hasOldPrice ? "#FF1A2A" : "inherit" }}
        >{price}</h4>
      </div>

      <div className={classnames(styles.card__footer)}>
        {footer}
      </div>
    </div>
  )
}

export default PricingCard
