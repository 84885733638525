import PricingCard from "../PricingCard"
import Button from "../../button"
import classnames from "../../../helpers/classnames"
import * as React from "react"
import styles from "../pricing.module.scss"

const EbooksCard = ({ price, oldPrice, href, ...rest }) => {
  return (
    <PricingCard
      title="eBooks"
      oldPrice={oldPrice}
      price={price}
      footer={
        <Button
          id="main-ebooks-buy"
          href={href}
          width="200px"
          target="_blank"
          outline
          className={classnames("mx-auto mt-0")}
        >ENROLL NOW</Button>
      }
    >
      <ul>
        <li>
          <strong>14 eBooks </strong>(in total 450+ pages)
        </li>
        <li>
          <strong>Ideation prompts</strong>
        </li>
      </ul>
    </PricingCard>
  )
}

export default EbooksCard
